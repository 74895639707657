// extracted by mini-css-extract-plugin
export var careerWorking__btn = "dR_GR";
export var careerWorking__btnWrapper = "dR_GQ";
export var careerWorking__card = "dR_GF";
export var careerWorking__cardBgDark = "dR_GH";
export var careerWorking__cardBgLight = "dR_GG";
export var careerWorking__cardDescription = "dR_GN";
export var careerWorking__cardHorizontal = "dR_GJ";
export var careerWorking__cardHorizontalBox = "dR_GK";
export var careerWorking__cardHorizontalImage = "dR_GL";
export var careerWorking__cardImage = "dR_GP";
export var careerWorking__cardTitle = "dR_GM";